export const MARKER_MIN_ZOOM = 11.5;
export const SPIDERFY_FROM_ZOOM = 18;
export const VIEW_PORT_DECIMAL = 12;

export const DEFAULT_READING_COLUMNS_POOLING = [
  'Tract_ID',
  'Flow_Meter_Serial_Number',
  'Fall_Current_Year_Reading',
  'Fall_Last_Year_Reading',
  'Date_Meter_Read',
];

export const DEFAULT_LANDOWNERS_INLINE_FIELDS = [
  'name',
  'county',
  'Cert_ID',
  'Alias',
  'legal',
  'wel_reg',   // this is not a comprehensive list of options rather a subset iff no values for params are present and this section has nto been removed 
];

export const DEFAULT_READING_COLUMNS_DAUPAU = [
  'Flow_Meter_ID',
  'Flow_Meter_Serial_Number',
  'Fall_Current_Year_Reading',
  'Fall_Last_Year_Reading',
  'Date_Meter_Read',
];

export const DEFAULT_ENTITY_COLUMNS = ['well_results'];

export const CALLS_LIMIT_PER_SEC = 200;
