const iconUrls = {
  well: '/icons/irrigation_well.png',
  irrWell: '/icons/irrigation_well.png',
  irrWellRed: '/icons/irrigation_well_red.png',
  irrWellGreen: '/icons/irrigation_well_green.png',
  irrWellSelect: '/icons/irrigation_well_select.png',
  monWell: '/icons/monitoring_well.png',
  monWellRed: '/icons/monitoring_well_red.png',
  monWellGreen: '/icons/monitoring_well_green.png',
  monWellSelect: '/icons/monitoring_well_select.png',
  miWell: '/icons/municipal_well.png',
  fmGreen: '/icons/flow_meter_full_green.png',
  fmRed: '/icons/flow_meter_full_red.png',
  chemigation: '/icons/chemigation.png',
  soil: '/icons/soil_sample.png',
  dams: '/icons/dam.png',
};

export default iconUrls;
