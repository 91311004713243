import { useMemo } from 'react';

function getIconType(key, data) {
  switch (key) {
    case 'wells':
    case 'irrWells':
    case 'monWells':
    case 'miWells':
      switch (data?.Well_Type) {
        case 'Irrigation':
          switch (data?.Color) {
            case 'red':
              return 'irrWellRed';
            case 'green':
              return 'irrWellGreen';
            case 'yellow':
              return 'irrWellSelect';
            default:
              return 'irrWell';
          }
        case 'Monitoring (Quality)':
          switch (data?.Color) {
            case 'red':
              return 'monWellRed';
            case 'green':
              return 'monWellGreen';
            case 'yellow':
              return 'monWellSelect';
            default:
              return 'monWell';
          }
        case 'Municipal':
        case 'Commercial/Industrial':
          return 'miWell';
        default:
          switch (data?.Color) {
            case 'red':
              return 'irrWellRed';
            case 'green':
              return 'irrWellGreen';
            case 'yellow':
              return 'irrWellSelect';
            default:
              return 'well';
          }
      }
    case 'flowmeters':
      if (data?.hasCurrentRead) return 'fmGreen';
      return 'fmRed';
    case 'chemigations':
      return 'chemigation';
    case 'soils':
      return 'soil';
    case 'dams':
      return 'dams';
    default:
      throw new Error('Invalid key');
  }
}

// combines wells, FMs and chemigations (for clustering)
export default function useCombinedPointGeom({
  wellsGeo, flowmetersGeo, chemigationsGeo, soilsGeo, damsGeo,
}) {
  const pointGeom = useMemo(() => {
    // Create an object to store features by the specific layer type
    const featuresByType = {
      irrWells: [],
      monWells: [],
      miWells: [],
      wells: [],
      flowmeters: [],
      chemigations: [],
      soils: [],
      dams: [],
    };
//console.log(damsGeo);
    [
      { key: 'irrWells', type: 'Well', data: wellsGeo },
      { key: 'monWells', type: 'Well', data: wellsGeo },
      { key: 'miWells', type: 'Well', data: wellsGeo },
      { key: 'wells', type: 'Well', data: wellsGeo },
      { key: 'flowmeters', type: 'Flowmeter', data: flowmetersGeo },
      { key: 'chemigations', type: 'Chemigation', data: chemigationsGeo },
      { key: 'soils', type: 'Soil', data: soilsGeo },
      { key: 'dams', type: 'Dam', data: damsGeo },
    ].forEach(({ key, type, data }) => {
      if (data) {
        if (data.type === 'FeatureCollection') {
          const filteredFeatures = data.features
            .map((feature) => {
              feature.properties.type = type;
              feature.properties.iconType = getIconType(key, feature.properties);
              feature.properties.category = key;
              feature.properties._id = feature.properties._id;

              return feature;
            })
            .filter((feature) => {
              if (type !== 'Well') return true;

              const wellType = `${feature.properties.iconType}s`;
              if (feature.properties.Mon_Active
              ) {
                return key === 'monWells';
              }
              return key === wellType.replace('Red', '').replace('Green', '').replace('Select', '');
            });

          // Add the filtered features to the correct layer key in the result object
          featuresByType[key].push(...filteredFeatures);
        } else {
          const iconType = getIconType(key, data.properties);
          if (
            key === 'flowmeters'
                || key === 'chemigations'
                || key === 'soils'
                || key === 'dams'
                || key.slice(0, -1) === iconType
          ) {
            featuresByType[key].push({
              ...data,
              properties: {
                ...data.properties,
                type,
                iconType,
                category: key,
              },
            });
          }
        }
      }
    });

    // Return the object with categorized features by key
    return featuresByType;
  }, [wellsGeo, flowmetersGeo, chemigationsGeo, soilsGeo, damsGeo]);

  return pointGeom;
}